:root {
  --accent-color: #00EFBE;
  --highlight-color: #abeee0;
  --main-color: #008166;
  --main-bg: #000C17;

  --main-transparent: rgb(0, 129, 101, 0.4); /* main but translucent */
  --dark-transparent: rgba(50, 50, 50, 0.3);

  --highlight-gradient: radial-gradient(circle, var(--main-color) 0%, var(--highlight-color) 100%);;
}
:root.light {
  --accent-color: #3a55bd;
  --highlight-color: #000C17;
  --main-color: #173195;
  --main-bg: #ededf1;

  --main-transparent: rgba(23, 49, 149, 0.6);
  --dark-transparent: hsla(0,0%, 55%, .3);
}

body {
  margin: 0;
  font-family: muli, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main-bg);
  color: var(--main-color);
  scroll-behavior: smooth;
}

body * {
  transition: color 150ms ease-in-out;
  will-change: color;
}

*::-webkit-scrollbar {
  width: 1em;
}

*::-webkit-scrollbar-track {
  background-color: var(--dark-transparent);
  border-radius: 0;
}
*::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: var(--main-transparent);
  border: none;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  margin: 0.2em 0;
}
h1 {
  color: var(--accent-color);
  font-size: 4em;
}
h2 {
  font-size: 2.4em;
}

a, a:link, a:visited {
  color: var(--accent-color);
  text-decoration: 2px var(--main-color) underline dotted;
}
a:hover {
  color: var(--highlight-color);
}

.middle {
  vertical-align: middle;
}

button {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid var(--main-color);
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  color: var(--accent-color);
  text-align: center;
  background: transparent;
  transition: all 0.2s;
}
button:hover{
  color: var(--main-bg);
  background-color: var(--highlight-color);
}

form {
  background-color: var(--dark-transparent);
  border-radius: .2em;
  padding: 1em;
}

input, select, textarea {
  width: 100%;
  padding: 0.4em;
  border: 1px solid var(--main-color);
  border-radius: 0.2em;
  box-sizing: border-box;
  margin: 0.4em 0;
  resize: none;
  vertical-align: middle;
  background: var(--main-bg);
  color: var(--main-color);
}
::placeholder {
  color: var(--main-color);
}

:focus {
  color: var(--accent-color);
  border: 1px solid var(--highlight-color);
  outline: none;
}

input:disabled, select:disabled, textarea:disabled, button:disabled {
  cursor: not-allowed !important;
  background: var(--dark-transparent);
}

input[type=submit], button {
  cursor: pointer;
}

input.toggle {
  padding: 0;
  margin: 0 0.4em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 5.4em;
  height: 2.5em;
  display: inline-block;
  position: relative;
  border-radius: 2em;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--dark-transparent);
  transition: background-color ease 0.3s;
  resize: none;
}

input.toggle:before {
  content: "on off";
  top: 0.1em;
  left: 0.1em;
  line-height: 2.5em;
  display: block;
  position: absolute;
  z-index: 2;
  width: 2.3em;
  height: 2.3em;
  background: var(--highlight-color);
  color: var(--main-color);
  border-radius: 50%;
  text-transform: uppercase;
  font-weight: bold;
  text-indent: -2em;
  word-spacing: 3em;
  text-shadow: -1px -1px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

input.toggle:checked {
  background-color: var(--accent-color);
}

input.toggle:checked::before {
  color: var(--main-bg);
}

input.toggle:checked:before {
  left: 3em;
}

hr {
  display: block;
  border: 1px solid var(--main-color);
  width: 100%;
}

.vr {
  display: inline-block;
  border-left: 2px solid var(--main-color);
  height: 1.5em;
  margin: 0 0.4em;
  vertical-align: middle;
}

img {
  width: 100%;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(8, 40%);
  grid-gap: 1em;
}
figure {
  margin: 0;
  padding: 1em;
  background: var(--dark-transparent);
  border-radius: 0.5em;
}

.blurred {
  filter: blur(50px);
}
.circle {
  position: fixed;
  opacity: 0.2;
  width: 60em;
  height: 60em;
  fill: var(--main-color)
}

.text {
  width: 370px;
  margin: 0 auto;
  margin-bottom: 3em;
}

.text h2 {
  text-align: right;
  margin: 0;
}

.socialLink {
  margin: 0;
  margin-left: 1em;
  display: inline-block;
  background-color: var(--main-color);
  border-radius: 100%;
  height: 4em;
  width: 4em;
  position: relative;
  transition: all 200ms ease-in-out;
  opacity: 0.7;
}
.socialLink:hover {
  opacity: 1;
}
.socialLink:first-of-type {
  margin-left: 0;
}

.socialLink svg {
  stroke: var(--main-bg);
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 60%;
  width: 60%;
  transition: all 100ms ease-in-out;
}
.socialLink:hover svg {
  stroke: var(--highlight-color);
}

.main {
  width: 70%;
  padding: 1em;
  position: absolute;
  left: 0;
  top: 1em;
  padding: 1em;
  margin: 0;
  height: 100%;
  max-height: 100vh;
  height: calc(100% - 7em);
  transition: all 100ms ease-in;
  overflow-y: auto;
}

.main li {
  line-height: 1.2em;
  margin-bottom: 0.2em;
}

.main li svg {
  height: 1.2em;
}

.centered, .normal {
  position: absolute;
  text-align: left;
  min-width: 600px;
  margin: 0 auto;
  padding: 6em 0em;
  left: 50%;
}

.centered {
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.normal {
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

nav ul {
  list-style-type: none;
}

nav {
  display: block;
  position: fixed;
  padding: 0;
  top: 50%;
  right: 2em;
  transform: translate(0, -50%);
  font-size: 3em;
  text-align: left;
  width: 19%;
  min-width: 300px;
  white-space: nowrap;
  overflow: auto;
  max-height: 100vh;
  z-index: 3;
  transition: transform 300ms ease-in-out;
  will-change: transform;
}

nav.hidden {
  transform: translate(50%, -50%) scaleX(0);
}


nav a, nav a:link, nav a:visited {
  color: var(--main-color);
  text-decoration: none;
}

nav a.active {
  color: var(--accent-color);
}

nav a:hover {
  color: var(--highlight-color);
}

nav ul {
  position: relative;
  font-size: 0.8em;
  margin-bottom: 0.4em;
}

footer {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.1em 20em;
  width: calc(100% - 40em);
  min-width: 300px;
  line-height: 3em;
  background: var(--dark-transparent);
  z-index: 2;
  white-space: nowrap;
}

.winWindow {
  min-height: 1.8em;
  width: 95%;
  margin: 0 auto;
  border: 1px solid var(--main-color);
  border-radius: 0.2em;
  transition: all 400ms ease-in-out;
  position: relative;
  margin-bottom: 0.8em;
}
.winWindow > div:first-child {
  background-color: var(--main-color);
  width: 100%;
  text-align: right;
  color: var(--main-bg);
  line-height: 1.6em;
}
.winWindow > div:first-child * {
  height: 1em;
  width: 1em;
  margin-left: 0.2em;
  vertical-align: middle;
}
.winWindow .content {
  padding: 1em .7em;
  min-height: 1.2em;
}
.winWindow p {
  margin: 0 0.3em;
  margin-bottom: 0.8em;
}
.winWindow .content img {
  border-radius: 0.1em;
}

nav li {
  margin: 0.2em 0;
}

.far {
  margin-top: 10em;
}

.burger {
  position: fixed;
  top: 50%;
  right: 2em;
  font-size: 3em;
  z-index: 100;
  -ms-transform: translate(0, -6em);
  transform: translate(0, -6em);
  padding: 0.2em;
  padding-bottom: 0;
  background: transparent;
  border-radius: 0.2em;
}
.burger svg {
  height: 1em;
  width: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas.background {
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}

.rating > div {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.rating .on {
  color: var(--highlight-color);
}
.rating .off {
  color: var(--main-color);
  opacity: 0.4;
}

.box {
  border: 0.1em solid var(--highlight-color);
  border-radius: 0.4em;
  min-height: 3em;
}

.table {
  display: table;
  border-spacing: 0.15em 0.4em;
}
.tr {
  display: table-row;
}
.td {
  display: table-cell;
}

.react-pdf__Page__textContent {
  transform: none !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
}

@media only screen and (max-width: 1400px) {
  .main {
    width: 60%;
  }
}

@media only screen and (max-width: 1200px), 
screen and (max-height: 700px) {
  nav, .main {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    -ms-transform: unset;
    transform: unset;
  }

  .main {
    padding: 1em 0.1em;
    width: calc(100vw - 0.2em);
  }

  nav {
    background-color: var(--main-bg);
    border-bottom: 1px solid var(--highlight-color);
    min-width: unset;
  }

  nav.hidden {
    transform: translate(0, -100%);
  }

  .burger {
    top: 0.4em;
    right: 0.6em;
    -ms-transform: unset;
    transform: unset;
    background: var(--dark-transparent);
  }

  .far {
    margin-top: 2em;
  }

  .centered, .normal {
    position: relative;
    top: unset;
    left: unset;
    margin: 0 auto;
    width: unset;
    height: unset;
    -ms-transform: unset;
    transform: unset;
    min-width: unset;
    padding: 1em;
  }

  .winWindow {
    width: 95%;
  }

  .blurred {
    filter: blur(20px);
  }

  .circle {
    width: 20em;
    height: 20em;
  }

  .table, .tr {
    display: inline-block;
  }
  .td {
    display: block;
    margin: 0.6em;
  }
  
  .socialLink {
    height: 3em;
    width: 3em;
    margin-left: 0.6em;
  }

  footer {
    padding: 0 4em;
    overflow-x: auto;
    width: calc(100% - 8em);
  }
}
@media only screen and (max-width: 500px) {
  h1 {
    font-size: 3em;
  }

  .text {
    width: 100%;
    max-width: 280px;
  }

  footer {
    padding: 0 2em;
    width: calc(100% - 4em);
  }
}